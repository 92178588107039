import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Wizard } from '@opengov/component-library/capital';
import { FigmaEmbed, PatternExample, UsageBlock } from '../../../components';
import LayoutComponents from '../../../components/capital/Layouts/LayoutComponents';
export const _frontmatter = {
  "title": "Wizard",
  "componentId": "wizard",
  "description": "Wizards are used to display steps and status within a wizard flow.",
  "activeTab": "components"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = LayoutComponents;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p><strong parentName="p">{`Documentation in progress------------`}</strong></p>
    <h2>{`Variants`}</h2>
    <PatternExample example={<Wizard activeStepIndex={1} numberOfSteps={4} mdxType="Wizard">
  <Wizard.Step label="Step One" />
  <Wizard.Step label="Step Two" />
  <Wizard.Step label="Step Three" />
  <Wizard.Step label="Step Four" />
    </Wizard>} description="The wizard layout is used for setup, object creation, etc." mdxType="PatternExample" />
    <h3>{`Usage`}</h3>
    <UsageBlock mdxType="UsageBlock">
  <UsageBlock.Correct>
    <p>...</p>
  </UsageBlock.Correct>
  <UsageBlock.Incorrect>
    <p>...</p>
  </UsageBlock.Incorrect>
    </UsageBlock>
    <h2>{`Layout`}</h2>
    <PatternExample example={"..."} title="..." description={"Description about layout structure"} mdxType="PatternExample" />
    <h2>{`Content`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      